body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.site-container {
  min-height: 100vh;
}
main {
  flex: 1;
}
.btn-primary,
.btn-outline-primary {
  background-color: #04a6e4;
  color: white;
}
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rating span {
  color: #ffc000;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}
.product img {
  width: 100%;
  max-width: 400px;
}
.product-info {
  padding: 1rem;
}
.img-large {
  max-width: 100%;
}
.img-thumbnail {
  height: 80px;
}
.small-container {
  max-width: 600px;
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
}

/* sidebar */
.navbar-brand {
  font-weight: bold;
}
.side-navbar {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #100901;
  transition: 0.5s;
}

.site-container {
  transition: 0.4s;
}
.active-cont {
  margin-left: 240px;
}
.active-nav {
  left: 0;
}
.text-bold {
  font-weight: bold;
}

.about-p {
  text-align: justify;
  font-size: 19px;
}

.about-h1 {
  padding: 30px 0 15px 0;
}

.ucg-h2 {
  padding: 15px 0 5px 0;
}

.footer .row {
  flex-wrap: nowrap;
}
